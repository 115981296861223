@charset 'utf-8';

// ########################################################################## //
// VARIABLES

// # FONTS
$sans-serif-stack: var(--sans-serif-stack, sans-serif);
$serif-stack: var(--serif-stack, serif);
$monospace-stack: var(--monospace-stack, monospace);

// # COLOURS
$colour-main: var(--colour-main, #efefef);
$colour-main-translucent: var(--colour-main-translucent, #efefef99);
$colour-main-tint: var(--colour-main-tint, #f6f6f6);
$colour-white: #fff;
$colour-black: #000;

$colour-header-link-background-thispage: var(--colour-header-link-background-thispage, #ffffff);
$colour-header-link-background-hover: var(--colour-header-link-background-hover, #ffffff44);
$colour-home-offering: var(--colour-home-offering, $colour-main);

$colour-cp-highlight: var(--colour-cp-highlight, $colour-main);
$colour-cp-highlight-tint: var(--colour-cp-highlight-tint, $colour-main-tint);

// # IMAGES
$image-background: var(--image-background, none);

:root {
  --colour-diff-15: #F44336;
  --colour-diff-10: #FF8000;
  --colour-diff-5: #FFFF4E;
  --colour-diff-0: #FFFED0;
  --colour-diff0: #D4FED5;
  --colour-diff5: #A9E7A6;
  --colour-diff10: #64CF63;
  --colour-diff15: #00B500;

  --p: 10px;

  // Default tooltip offsets for top-right of report pages.
  --tooltip-2: 50%;
  --tooltip-3: 50%;
  --tooltip-4: 50%;
  --tooltip-5: 50%;
  --tooltip-6: 50%;
  --tooltip-7: 50%;
  --tooltip-8: 50%;
}

// ########################################################################## //
// MIXINS

@mixin cross-browser($property, $value) {
  -webkit-#{$property}: $value;
     -moz-#{$property}: $value;
      -ms-#{$property}: $value;
          #{$property}: $value;
}

@mixin mpb($margin:0, $padding:0, $border:0) {
    margin: $margin;
    padding: $padding;
    border: $border;
}

// ########################################################################## //
// DECLARATIONS

// -------------------------------------------------------------------------- //
// # STRUCTURE

// Replaces standard box model with one where width/height are the actual
// visable width/height. Padding & border cut into the box.

html {
  box-sizing: border-box;
  @include mpb();
  @include cross-browser('font-smoothing', antialiased);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;

  background-color: $colour-main;
  background-image: $image-background;
  background-size: var(--background-size, 100%);
  background-repeat: var(--background-repeat, repeat-y);
  background-position: var(--background-position, 0 -100px);

  color: var(--colour-text, #000000);
  
  &.survey-thankyou {
    background-color: var(--colour-thankyou-background, $colour-main);
    background-image: var(--thankyou-background-image, $image-background);
    background-size: var(--thankyou-background-size, var(--background-size, 100%));
    background-repeat: var(--thankyou-background-repeat, var(--background-repeat, repeat-y));
    background-position: var(--thankyou-background-position, var(--background-position, 0 -100px));
    color: var(--colour-thankyou-text, var(--colour-text, #000000));
  }

  font-family: $serif-stack;
  overflow-y: scroll;
}

body {
  @include mpb(0, 40px, 0);
  display: grid;
  width: calc(100% - 80px);
  grid-template-columns: 320px calc(100% - 320px);
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 40px;
  min-height: calc(100vh - 80px);
}

.hide, .hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.clear {
  clear: both;
}

.crisp {
  transform: translate(0, 0);
  shape-rendering: crispEdges;
}


header {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;

  background-color: $colour-main;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 20px 40px;
  margin: -40px -40px 0 -40px;
  box-shadow: var(--shadow, 0 0 20px #00000022);
  border-bottom: var(--border-content, none);

  span {
    a, button {
      display: inline-block;
      width: auto;
      
      margin: 0 20px 0 0;
      padding: 10px 14px 9px 14px;

      font-family: $sans-serif-stack;
      font-size: 15px;
      font-weight: 700;
      text-decoration: none;
      color: var(--colour-text, #000) !important;

      border-radius: var(--border-radius, 5px);
      border: 0;
      outline: 0;
      cursor: pointer;
      background-color: transparent;
    }

    a:hover, button:hover {
      background-color: $colour-header-link-background-hover;
    }

    a.thispage {
      background-color: $colour-header-link-background-thispage;
      cursor: default;
    }

    button {
        margin-right: 0;
    }

    span {
      font-family: $sans-serif-stack;
      margin: 0 20px 0 0;
    }
  }
}

footer {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: auto;
  width: 100%;
  font-family: $sans-serif-stack;
  font-size: 14px;
  font-weight: 400;

  align-items: end;
  grid-column-gap: 40px;

  .support {
    
    font-size: 1.1rem;
    font-weight: 700;
    
    a {
      text-decoration: none;
    }
  }
}

div.footer_left {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  align-self: end;

  font-size: .7rem;
}

img.footer_logo {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  height: 70px;
  align-self: end;
  justify-self: end;
}

section {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  align-self: start;

  display: block;
  position: relative;
  background-color: $colour-white;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  border: var(--border-content, none);
  border-radius: var(--border-radius, 5px);
  box-shadow: var(--shadow, 0 0 20px #00000022);
  overflow: hidden;
  
  header {
    position: relative;
    z-index: unset;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;

    margin: -20px -20px 0 -20px;
    width: calc(100% + 40px);
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border: none;

    h1 {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      padding: 20px;
    }

    nav {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      display: flex;
      margin: 0;

      width: auto;
      background-color: transparent;

      button {
        display: flex;
        align-items: center;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        font-size: 20px;
        width: auto;
        padding: 20px 15px;
        // background-color: #fedb4a22;
        color: #000;
        cursor: pointer;
        position: relative;

        &:first-of-type {
          padding-left: 20px;
        }
        &:last-of-type {
          padding-right: 20px;
        }

        span.disabled {
          cursor: not-allowed;
          color: #bbb;
        }

        span.mono {
          font-size: 1.2rem;
          font-family: $monospace-stack;
          font-weight: 700;
          margin-right: 10px;

          &.A, &.B {
            border-radius: 5px;
            padding: 2px 5px;
          }

          &.A {
            background-color: #fedb4a;
          }

          &.B {
            background-color: #c0ca33;
          }

        }

        &.inert {
          cursor: default;
          font-family: $sans-serif-stack;
          font-weight: 300;
          padding: 20px 5px;
          // font-size: .9rem;
        }

        .tooltip {
          visibility: hidden;
          content: attr(data-label);
          position: absolute;
          top: calc(100% - 10px);
          right: 50%;
          transform: translateX(50%);
          white-space: nowrap;
          // min-width: 100px;
          box-sizing: border-box;
          
          background-color: #000;
          color: #fff;  
          padding: 5px 10px;

          font-size: .8rem;
          // font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-radius: 5px;
          z-index: 99;
        }

        &:nth-last-child(1) .tooltip {
            right: 20px;
            transform: none;
        }
        @for $i from 2 through 8 {
          &:nth-last-child(#{$i}) .tooltip {
              right: var(--tooltip-#{$i});
          }
        }

        &:hover {
          .tooltip {
            visibility: visible;
          }
        }
      }
    }

    .modal {
      margin: 0 20px 20px 20px;
      border: 1px solid #ccc;
      grid-column: 1 / 3;
      padding: 20px;
      border-radius: 5px;

      .count-table {
        width: fit-content;
      }

      .counts-layout {
        display: flex;
        flex-wrap: wrap;

        & > div {
          margin: 0 20px 20px 0;
        }
      }

      .group-label {
        margin: 0;
        font-size: 1.2rem;
        font-family: $sans-serif-stack;
        span {
          font-weight: 700;
          text-decoration-thickness: 3px;
          width: 25px;
          border-radius: 5px;
          display: inline-block;
          text-align: center;
        }
        &.A {
          span {
            background-color: #fedb4a;
          }
        }
        &.B {
          span {
            background-color: #c0ca33;
          }
        }
      }
    }

  }

  .info {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;

    button {
      display: block;
      width: 100%;
      border: 0;
      margin: 40px 0 0 0;
      padding: 10px 20px;
      font-family: $sans-serif-stack;
      font-size: .9rem;
      cursor: pointer;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
}


main {
  box-sizing: border-box;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  align-self: start;
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: transparent;

  &.opaque {
    padding: 20px;
    background-color: $colour-white;
    box-shadow: 0 0 20px #00000022;
  }
}

svg {
  /*background-color: #fbfbfb;*/
  overflow: visible;
}

svg#svg {
  width: 100%;
  margin-bottom: 20px;
}

svg.offscreen {
  position: fixed;
  top: 0;
  left: calc(100vw + 1000px);
}

canvas#canvas, canvas#canvas2, canvas#canvas3, canvas#canvasLegend {
  display: none;
}

// -------------------------------------------------------------------------- //
// # TEXT FORMATTING

h1 {
  font-family: $sans-serif-stack;
  font-weight: 300;
  font-size: 20px;
  display: block;
  margin: 0;
}

h2 {
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin: 0 0 8px 0;

  em {
    font-weight: 300;
    font-style: normal;
  }
}

h5 {
  display: inline-block;
  background-color: $colour-main;
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin: 12px 0 4px 0;
}

h6 {
  font-family: $sans-serif-stack;
  text-decoration: underline;
  font-weight: 300;
  font-size: 16px;
  margin: 12px 0 4px 0;
  text-transform: uppercase;
}

h7 {
  font-family: $sans-serif-stack;
  font-weight: 300;
  font-size: 16px;
  margin: 12px 0 4px 0;
  font-style: italic;
}

h3 {
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #f6f6f6;
  padding: 6px 10px;
  margin: 8px 0 8px 0;

  &:focus {
    outline: none;
  }
}

h4 {
  font-family: $sans-serif-stack;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 20px 0 0 0;
}

p {
  display: block;
  margin: 0 0 4px 0;
  max-width: 100%;
}

a {
  color: var(--colour-text, #000);
}

ol, ul {
  list-style: none;
}

hr {
  display: block;
  width: 100%;
  height: 6px;
  background-color: #eee;
  border: none;
  margin: 16px 0;
}

strong {
  font-family: $sans-serif-stack;
  font-weight: 700;
}

img.sample {
  float: left;
  border: 1px solid $colour-main;
  margin: 10px 10px 0 0;
  width: 100px;
}

p.endnote {
  font-family: $sans-serif-stack;
  font-weight: 400;
  font-size: .95rem;
}

p.endnote a {
  color: #000;
}

span.muted {
  font-weight: 700;
}

span.boldcaps {
  font-family: $sans-serif-stack;
  font-weight: 700;
}

.small-caps {
  text-transform: lowercase;
  font-variant: small-caps;
}

// -------------------------------------------------------------------------- //
// TABLES

table {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #000;
  border-collapse: collapse;
  margin: 0 0 20px 0;

  tr:nth-child(even) {
    background-color: #f6f6f6;
  }
  
  th {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .8rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 20px 8px 6px 8px;

    &:after{
        content:'';
        position:absolute;
        left: 0;
        bottom: 0;
        width:100%;
        border-bottom: 1px solid #000;
    }
  }

  td {
    padding: 6px 8px;
    font-family: $monospace-stack;
  }

}

// -------------------------------------------------------------------------- //
// COUNT TABLE

table.count-table {
  border-bottom: none;
  text-align: center;
  margin-bottom: 0;
  
  td.gender {
    text-align: right;
    font-weight: 700;
  }
  
  td.grade {
    font-weight: 700;
  }
  
  td.grade-total {
    font-weight: 700;
    border-top: 1px solid #000;
  }
  
  td.gender-total {
    font-weight: 700;
    border-left: 1px solid #000;
  }
}

div.cp-table-of-counts {
  display: block;
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #efefef;
  border: 1px solid #000;
  z-index: 999;
  margin-bottom: 0;
}

// -------------------------------------------------------------------------- //
// # SURVEY LOGIN AND THANKYOU PAGE

html.survey-login, html.survey-thankyou {
  overflow-x: hidden;
  overflow-y: hidden;

  h3 {
    display: inline-block;
    background-color: $colour-main;
  }

  p {
    width: 700px;
    margin-bottom: 1rem;
  }
  
  div.fineprint {
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-family: $sans-serif-stack;
    font-weight: 300;
    font-size: 10px;
  }

  span.thankyou-head {
    display: block;
    font-family: $sans-serif-stack;
    font-weight: 700;
    font-size: 3rem;
  }

  span.thankyou-message {
    display: block;
    font-family: $sans-serif-stack;
    font-weight: 700;
    font-size: 1.4rem;
    margin: 0 0 40px 0;
  }

  a.telephone {
    display: inline-block;
    background-color: var(--colour-thankyou-telephone-background, #ffffff44);
    text-decoration: none;
    color: #000 !important;
    padding: 0 3px;
    font-family: $monospace-stack;
    font-weight: 700;
  }

  img.thankyou-logo {
    display: block;
    width: 30%;
    margin: 40px auto 0 auto;
  }

  img.squiggle {
    display: block;
    width: 50%;
    margin: 4em auto;
  }

  span.loginlabel {
    font-family: $sans-serif-stack;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }

  span.loginerror {
    font-family: $sans-serif-stack;
    font-size: 14px;
    display: block;
    overflow: hidden;
    color: var(--colour-error, red);
    transition: height .3s ease;
  }

  .login input {
    display: inline-block;
    width: 54px;
    margin : 0 0 0 0;
    border: 0;
    padding: 10px;
    font-size: 32px;
    font-family: $monospace-stack;
    font-weight: 700;
    text-align: center;
    opacity: .8;
    z-index: -1 !important;
    text-transform: uppercase;
  }

  .login input:focus {
    outline: none;
    opacity: 1;
  }

  .login .input {
    display: inline-block;
    margin : 0 0 0 0;
    border: 0;
    padding: 10px;
    font-size: 32px;
    font-family: $monospace-stack;
    font-weight: 700;
  }

  .login button {
    display: block;
    width: auto;
    float: right;
    margin : 0 0 0 0;
    border: 0;
    padding: 10px 20px;

    font-family: $sans-serif-stack;
    font-size: 14px;
    font-weight: 700;

    background-color: var(--colour-login-button, #000);
    color: var(--colour-login-button-text, $colour-main);
    cursor: pointer;
  }

  .login button:hover {
    background-color: var(--colour-login-button-hover, #000);
    color: var(--colour-login-button-text-hover, #fff);
  }

  .spinnerBox {
    display: none;
    position: relative;
    width: 100%;
    padding: 30px 0;
    margin: -120px auto 0 auto;
    background-color: $colour-main-translucent;
    z-index: 99 !important;
  }

  .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 20px auto;
  }

  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
}

.login-field {
  background-color: var(--colour-login-background, #ffffff);
  color: var(--colour-login-text, #000000);
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

@media (max-width: 600px) {
  html.survey-login, html.survey-thankyou {
    /* For tablets: */
    div.centre-box {
      display: table-cell;
      vertical-align: top;
      width: 100%;
      margin: 0 auto;
    }
    span.thankyou-head {
      font-size: 60px;
    }
    span.thankyou-message {
      font-size: 40px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    div.centre-box p {
      font-size: 32px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    .login input {
    width: 38px;
    padding: 10px;
    font-size: 24px;
    }
  }
}

// -------------------------------------------------------------------------- //
// # SECTION: HOME

main.home {

  font-family: $sans-serif-stack;

  h2 {
    font-weight: 300;
    margin: 0 0 20px 0;
  }
  
  .section {
    padding: 20px;
    margin: 0 0 40px 0;
    background-color: $colour-white;
    border-radius: var(--border-radius, 5px);
    box-shadow: var(--shadow, 0 0 20px #00000022);
    overflow: hidden;
    border: var(--border-content, none);
  }

  .surveys {
    padding-top: 0;

    .table {

      display: grid;
      border: 0;
      margin: 0;

      .tr {
        grid-column: 1 / 7;
        border-radius: 5px;

        &.odd {
          background-color: #f6f6f6;
        }
      }

      .th {
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: .8rem;
        position: sticky;
        top: 0;
        background-color: #fff;
        padding: 20px 8px 6px 8px;
      }

      .td, .th {
        &.rl {
          text-align: right;
        }
      }

      .td {
        display: flex;
        padding: 10px;
        font-size: .95rem;

        &.mono {
          font-family: $monospace-stack;
          font-size: 1rem;
        }

        &.rl {
          justify-content: flex-end;
        }

        &.no-padding {
          padding: 0;
        }

        &.vertical {
          flex-direction: column;
        }

        .status {

          display: flex;
          width: 100%;
          box-sizing: border-box;
          padding: 5px;
          // border: 5px solid #fff;
          // margin: 1px 0;

          align-self: stretch;
          align-items: flex-start;
          
          text-decoration: none;

          opacity: .9;

          &:hover {
            opacity: 1;
          }

          cursor: default;
          
          & > span {
            display: block;
            padding: 5px 10px;
            border-radius: 5px;
            width: 100%;
            text-align: center;
          }

          &.closed > span {
            background-color: var(--colour-status-closed, #efefef);
          }
          &.openingSoon > span {
            background-color: var(--colour-status-opening-soon, #efefef);
          }
          &.closedInsufficient > span {
            background-color: var(--colour-status-closed-insufficient, #ff6d00);
          }
          &.openSufficient > span {
            background-color: var(--colour-status-open, $colour-main-tint);
          }
          &.openInsufficient > span {
            background-color: var(--colour-status-open, $colour-main-tint);
          }
          &.closedSufficient > span {
            background-color: var(--colour-status-closed-sufficient, #9ccc65);
            cursor: pointer;
          }
        }
      }


      // .tr {
      //   .td > span {
      //     display: block;
      //     width: 100%;
      //     padding: 10px;
      //     box-sizing: border-box;
      //   }
        
      //   &:nth-child(odd) {

      //     .td {
      //       background-color: #f6f6f6;
      //       &:first-of-type,
      //       &:last-of-type {
      //         background-color: transparent;
      //       }
      //     }

      //     .td > span {
      //       background-color: #f6f6f6;
      //     }
      //   }

      //   .td:first-of-type span {
      //     border-top-left-radius: 5px;
      //     border-bottom-left-radius: 5px;
      //   }
      //   .td:last-of-type span {
      //     border-top-right-radius: 5px;
      //     border-bottom-right-radius: 5px;
      //   }
      // }


      // .th {
      //   padding: 10px 10px;

      //   &:after {
      //     display: none;
      //   }
      // }

      // .td {
      //   padding: 0;
      //   font-family: $sans-serif-stack;
      //   font-size: .95rem;
      //   font-weight: 300;
      //   vertical-align: top;

      //   &.mono {
      //     font-family: $monospace-stack;
      //     font-size: 1rem;
      //   }

      //   &.no-padding {
      //     padding: 0;
      //   }

      // }
    }

    p {
      font-size: .9rem;
    }
  }

}

a.offering {
  display: block;
  float: left;
  margin: 0 20px 20px 0;
  padding: 30px;
  font-family: $sans-serif-stack;
  text-decoration: none;
  text-align: center;
  color: #000;
  background-color: $colour-white;
  border-radius: var(--border-radius, 5px);
  box-shadow: var(--shadow, 0 0 20px #00000022);
  border: var(--border-content, none);
  
  &:hover {
    span {
      text-decoration: underline;
    }
  }

  i {
    display: inline-block;
    position: relative;
    bottom: -5px;
    font-size: 30px;
    margin: -20px 0 -5px 0;
  }
}

div#no-survey-message {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 60px 20px;
  background-color: #efefef;
  text-align: center;
  margin: 20px 0 20px 0;
  font-family: $sans-serif-stack;
}

.loadingdots:after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}

@keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
}

.status-bar {
  display: block;
  width: calc(100% + 40px);
  height: 20px;
  margin: -10px -20px 10px -20px;
  grid-template-columns: 20% 20% 20% 20% 20%;
  
  .status-bar-segment {
    float: left;
    width: 20%;
    height: 20px;
    -webkit-transition: width .5s;
    transition: width .5s;
  }

  .closedSufficient {
    background-color: var(--colour-status-closed-sufficient, #9ccc65);
  }
  .closedInsufficient {
    background-color: var(--colour-status-closed-insufficient, hsla(25.6, 100%, 50%, 1));
  }
  .openSufficient {
    background-color: var(--colour-status-open, hsla(47.8, 100%, 73.9%, 1));
  }
  .openInsufficient {
    // background-color: hsla(47.8, 100%, 73.9%, 1);
    background-size: 30px 30px;
    background-image: linear-gradient(45deg, var(--colour-status-open-tint, $colour-main-tint) 25%, var(--colour-status-open, $colour-main) 25%, var(--colour-status-open, $colour-main) 50%, var(--colour-status-open-tint, $colour-main-tint) 50%, var(--colour-status-open-tint, $colour-main-tint) 75%, var(--colour-status-open, $colour-main) 75%, var(--colour-status-open, $colour-main));
  }
  .openingSoon {
    background-color: hsla(0, 0%, 93.7%, 1);
  }

}

.loading-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  /*background-color: #f6f6f6;*/
  background-size: 30px 30px;
  background-image: linear-gradient(45deg, #f6f6f6 25%, #efefef 25%, #efefef 50%, #f6f6f6 50%, #f6f6f6 75%, #efefef 75%, #efefef);
  animation: barberpole 2s linear infinite;
}

.loading-text {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $monospace-stack;
}

.loading-progress {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  z-index: 0;
  width: 50%;
  background-color: #efefef;
  -webkit-transition: width .2s; /* Safari prior 6.1 */
  transition: width .2s;
  box-sizing: border-box;
  border-right: 1px solid $colour-main;
}

span.survey-detail {
  display: block;
  float: right;
  cursor: pointer;
  margin-left: 10px;
}

span.survey-clusters {
  display: block;
  font-size: .8rem;
  text-align: right;

  span {
    font-weight: 700;
    display: block;
  }
}

.survey-counts {
  display: block;
  float: right;
  font-weight: 300;

  .number {
    font-family: $monospace-stack;
  }
}


div.shades {
  display: table-cell;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  vertical-align: middle;
  padding-top: 200px;
  background-color: var(--colour-shades, rgba(254,219,74,.9));
}

div.popup {
  display: inline-block;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  z-index: 99;
  
  table {
    border-bottom: none;
    text-align: center;
    
    td.gender {
      text-align: right;
      font-weight: 700;
    }
    
    td.grade {
      font-weight: 700;
    }

    td.grade-total {
      font-weight: 700;
      border-top: 1px solid #000;
    }
    
    td.gender-total {
      font-weight: 700;
      border-left: 1px solid #000;
    }
  }
}


// -------------------------------------------------------------------------- //
// CONTROL PANEL


div#cp {
  display: block;
  width: 100%;
  margin: 0 0 20px 0;

  .cp-header {
    display: flex;
    justify-content: space-between;

    margin: 0 -20px;
    padding: 0 20px;

    font-family: $sans-serif-stack;

    .cp-tab,
    .cp-button {
      padding: 6px 10px 8px 10px;
      cursor: pointer;
    }

    .left {
      display: flex;
      justify-content: flex-start;

      .cp-tab {
        margin-right: 1rem;
        border: 3px solid #efefef;
        border-bottom: 0;
        font-family: $monospace-stack;

        &:hover,
        &.active {
          background-color: #efefef;
        }

      }

    }

  }

  .cp-body {
    // background-color: #efefef;
    margin: 0 -20px;
    padding: 0 20px;
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .duplication {
      width: 100%;
    }
  }
  
  .cp-footer {
    // background-color: #f6f6f6;
    margin: 0 -20px;
    padding: 0 20px;
  }

  div.cp-dim {
    display: grid;
    grid-template-columns: 70px 300px auto;
    grid-template-rows: auto auto;

    // margin: 0 0 20px 0;
    // border-bottom: 1px solid #fff;
    padding: 0 0 20px 0;
    
    div.cp-step {
      grid-column: 1 / 2;
      grid-row: 1 / 3;

      background-color: #000;
      color: #fff;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      display: grid;
      align-items: center;
      justify-content: center;
      font-family: $sans-serif-stack;
      font-weight: 700;
      font-size: 1.2rem;

    }

    div.cp-instruction {
      grid-column: 2 / 3;
      grid-row: 1 / 2;

      padding:  0 10px 5px 0;
      
      font-family: $sans-serif-stack;
      font-size: 1rem;
    }

    div.cp-elaboration {
      grid-column: 2 / 3;
      grid-row: 2 / 3;

      padding:  0 10px 0 0;

      font-family: $sans-serif-stack;
      font-size: .9rem;
      line-height: 1.2rem;
      color: #666;
    }

    div.cp-inputbox {
      grid-column: 3 / 4;
      grid-row: 1 / 3;

      display: flex;
      align-items: center;
      justify-content: center;

      /*margin-bottom: -5px;*/

      background-color: #f6f6f6;

      input[type=text] {
        display: block;
        outline: none;
        border: 0;
        width: 100%;
        height: 100%;
        padding: 5px;
        box-sizing: border-box;

        font-family: $monospace-stack;
        text-align: center;
        font-size: 1rem;

        background-color: transparent;
      }

      select {
        display: block;
        outline: none;
        border: 0;
        height: 100%;
        padding: 5px;
        box-sizing: border-box;

        font-family: $monospace-stack;
        text-align: center;
        font-size: 1rem;

        background-color: transparent;
      }

    }

    div.cp-label {
      display: none;
    }

  }


}


div#cp.compact {

  .cp-body[data-tab="surveys"] {
    padding: 10px 20px;
  }

  .cp-body[data-tab="responses"],
  .cp-body[data-tab="homepage"] {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #efefef;
    
    padding: 10px 10px 0 10px;
  }
  
  .cp-footer {
    padding: 10px 20px 0 20px;
    border-bottom: 1px solid transparent;
  }

  div#cp-toggle-compact::before {
    content: "Expand";
  }

  .duplication {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:nth-of-type(1) .cp-filter-button.on {
      background-color: var(--cp-filter-button-on, #fedb4a);
    }
    &:nth-of-type(2) {
      .cp-filter-button.on, .select.multi .input .multiselected .value {
        background-color: #c0ca33;
      }
    }

    div.cp-dim {
      width: 50%;
    }

    &.duplicated {
      div.cp-dim {
        width: 100%;
      }
      .group-label {
        margin: 0 10px 10px 10px;
        font-size: 1.2rem;
        font-family: $sans-serif-stack;
        span {
          font-weight: 700;
          text-decoration-thickness: 3px;
          width: 25px;
          border-radius: 5px;
          display: inline-block;
          text-align: center;
        }
      }
      &:nth-child(1) .group-label {
        span {
          background-color: #fedb4a;
        }
      }
      &:nth-child(2) .group-label {
        span {
          background-color: #c0ca33;
        }
      }
    }
  }

  div.cp-dim {
    grid-template-columns: 170px auto;
    grid-template-rows: auto;

    box-sizing: border-box;

    margin: 0;
    border: 0;
    padding: 0 10px 10px 10px;

    div.cp-step,
    div.cp-instruction,
    div.cp-elaboration {
      display: none;
    }

    div.cp-inputbox {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      display: block;

      input[type=text] {
        text-align: left;
        padding: 5px 10px;
      }

      select {
        text-align: left;
        padding: 5px 10px;
        width: 100%;
      }

    }

    div.cp-label {
      display: flex;

      grid-column: 1 / 2;
      grid-row: 1 / 2;
      align-items: center;
      /*align-self: center;*/

      font-family: $sans-serif-stack;
      font-size: .8rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: .7px;

    }
  }
}

div.cp-filter-box,
div.cp-checkbox-box {
  display: block;
  margin: -10px 0 0 0;
}

div.cp-filter-button {
  padding: 5px 10px;
  margin: 0 0 0 0;
  float: left;

  background-color: #fff;
  font-family: $monospace-stack;
  cursor: pointer;

  &.on {
    background-color: $colour-cp-highlight;
  }

  &.disabled {
    background-size: 10px 10px;
    background-image: linear-gradient(-45deg, #f6f6f6 25%, #fff 25%, #fff 50%, #f6f6f6 50%, #f6f6f6 75%, #fff 75%, #fff);
    color: #666;
    cursor: default;
  }
}

div.cp-filter-box {

  div.cp-button-box {
    margin: 10px 0 0 0;
    background-color: #000;
    float: left;

    &.divider {
      margin: 10px 0 0 0;
      background-color: transparent;
      float: left;

      &::before,
      &::after {
        display: block;
        float: left;
        padding: 5px 0;
        margin: 0 0px;
        width: 5px;
        color: transparent;
        font-family: $monospace-stack;
        content:'&nbsp;';
        z-index: 1;
      }

    }

    &.left div.cp-filter-button.on {
      background-color: var(--colour-female-translucent-flat, #b5e1a5);
    }
    &.right div.cp-filter-button.on {
      background-color: var(--colour-male-translucent-flat, #cda9f9);
    }
  }
}

div.cp-filter-button {

  &.F.on {
    background-color: #b5e1a5;
  }
  &.M.on {
    background-color: #cda9f9;
  }
  &.O.on {
    background-color: #ffb199;
  }
  &.P.on {
    background-color: #b4bdc0;
  }

}

// @media (max-width: 1610px) {
//   div#cp.compact div.cp-dim {
//     width: 100%;
//   }
// }

div.cp-checkbox {
  padding: 5px 10px;
  margin: 10px 10px 0 0;
  float: left;

  background-color: #fff;
  font-family: $monospace-stack;
  cursor: pointer;
  
  &::after {
    content: ' \2610';
    font-weight: 700;
    font-size: .85rem;
  }
  
  &.on::after {
    content: ' \2611';
    font-size: .85rem;
  }
}


div#cp-generate-pdf,
div#cp-retrieve-data,
div#ap-save,
div#ap-update,
div#ap-delete {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  background-color: $colour-cp-highlight;
  border: 1px solid $colour-cp-highlight;
  cursor: pointer;
  text-align: center;
  font-family: $monospace-stack;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  
  &:hover {
    border: 1px solid #000;
  }
  
  &.disabled {
    background-color: #efefef;
    border: 1px solid #efefef;
    cursor: default;
  }
  
  &.disabled:hover, &.disabled:hover {
    border: 1px solid #efefef;
  }

  &.loading {
    background-size: 30px 30px;
    background-image: linear-gradient(45deg, $colour-cp-highlight-tint 25%, $colour-cp-highlight 25%, $colour-cp-highlight 50%, $colour-cp-highlight-tint 50%, $colour-cp-highlight-tint 75%, $colour-cp-highlight 75%, $colour-cp-highlight);
    animation: barberpole 2s linear infinite;
    cursor: default;
    color: transparent;
  }
  
  &:active {
    background-size: 30px 30px;
    background-image: linear-gradient(45deg, $colour-cp-highlight-tint 25%, $colour-cp-highlight 25%, $colour-cp-highlight 50%, $colour-cp-highlight-tint 50%, $colour-cp-highlight-tint 75%, $colour-cp-highlight 75%, $colour-cp-highlight);
    animation: barberpole 2s linear infinite;
  }
  
  &.disabled:active {
    background-color: #efefef;
    background-size: 30px 30px;
    background-image: none;
    animation: none;
  }
}



@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}



div.cp-footer-block {
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: auto;
  width: 100%;
  margin: 0 0 10px 0;
}

div.cp-icon {
  grid-template-columns: 1 / 2;
  grid-template-rows: 1 / 2;

  color: #000;

  align-self: center;
  text-align: center;
}

div.cp-text {
  grid-template-columns: 2 / 3;
  grid-template-rows: 1 / 2;
  box-sizing: border-box;
  padding: 10px;

  background-color: rgba(255,255,255,.5);

  font-family: $monospace-stack;
}

div.cp-error {
  background-color: #ff8a65;
  
  div.cp-text {
    background-color: #ffccbc;
  }

  div.cp-icon {
    color: #dd2c00;
  }
}

div.cp-warning {
  background-color: #ffb74d;
  
  div.cp-text {
    background-color: #ffe0b2;
  }

  div.cp-icon {
    color: #e65100;
  }
}

div.cp-info {
  background-color: #ddd;
  
  div.cp-text {
    background-color: #efefef;
    font-size: .7rem;
    font-family: $sans-serif-stack;
  }
}

div.cp-loading-bar {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  
  width: calc(100% + 40px);
  margin: 0 -20px;
  /*background-color: #f6f6f6;*/
  background-size: 40px 40px;
  background-image: linear-gradient(45deg, #f6f6f6 25%, #efefef 25%, #efefef 50%, #f6f6f6 50%, #f6f6f6 75%, #efefef 75%, #efefef);
  animation: barberpole 2s linear infinite;
}

div.cp-loading-text {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  z-index: 10;
  
  text-align: center;
  font-family: $monospace-stack;
}

div.cp-loading-progress {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  z-index: 0;
  width: 0%;
  background-color: #efefef;
  -webkit-transition: width .2s; /* Safari prior 6.1 */
  transition: width .2s;
  box-sizing: border-box;
  border-right: 1px solid $colour-main;
}

[data-tab="surveys"].cp-body {
  font-family: $monospace-stack;
}

div.survey-selector-controls {
  width: 100%;
  margin: 0;
  padding: 0 0 10px 0;
  // background-color: #efefef;
  display: flex;
  flex-direction: row;
  color: #000;
}

div.survey-selector-text-container {
  flex-grow: 0;
}

div.survey-selector-search-container {
  padding: 0 0 0 40px;
  flex-grow: 1;
}

span.survey-selector-title {
  font-weight: 700;
}

span.survey-selector-instructions {
  font-size: .9rem;
  font-style: italic;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

div.survey-selector-search-container input {
  width: 100%;
  box-sizing: border-box;
}

div.cp-export-button {
  display: inline-block;
  padding: 5px 10px;
  font-family: $monospace-stack;
  cursor: pointer;
}

/* ============================================================================== */
/* ============================================================================== */
/* CUSTOM BUILT DROPDOWN ======================================================== */
/* ============================================================================== */

.curtain {
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;
  // -webkit-backdrop-filter: blur(3px);
  // backdrop-filter: blur(3px);
}

.select {

  position: relative;
  width: 100%;
  height: 100%;

  font-family: var(--monospace-stack);

  .input {
    display: grid;
    grid-template-columns: 1fr auto auto;

    box-sizing: border-box;
    height: 100%;

    background-color: #f6f6f6;
    cursor: pointer;

    .selected {
      display: grid;
      align-items: center;
      padding: 8px 10px;
      font-size: 1rem;
      word-break: break-all;
    }

    .multiselected {

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 var(--h) var(--h) 0;

      .value {
        display: grid;
        grid-template-columns: auto auto;
        background-color: var(--c-highlight);
        margin: var(--h) 0 0 var(--h);

        border-radius: var(--h);

        .label {
          padding: var(--q) var(--h);
          font-size: 1rem;
          cursor: default;
        }

        .unselect {
          padding: var(--q) var(--h);
          display: grid;
          align-items: center;
          border-left: 1px solid #00000022;
          color: #00000044;

          &:hover {
            color: black;
          }
        }
      }

    }

    .icon {
      padding: 5px;
      display: grid;
      align-items: center;

      color: var(--c-muted);

      &:hover, &.black {
        color: black;
      }

      &.clear {
        display: none;
      }
    }
  }

  &.open {

    .input {
      box-shadow: 0 4px 5px rgb(0 0 0 / 15%);
    }

  }

  .dropdown {
    display: block;
    position: absolute;
    width: 100%;

    box-shadow: 0 4px 5px rgb(0 0 0 / 15%);

    overflow-x: hidden;
    overflow-y: auto;

    z-index: 1000;

    background-color: #fff;

    font-size: 1rem;

    input {
      display: block;
      padding: var(--p) !important;
      border: 0;
      width: 100%;
      border-bottom: 1px solid #bcbcbc !important;
      background-color: #fff !important;

    }

    .options {
      max-height: 200px;
      overflow-y: auto;

      .heading {
        padding: var(--h) var(--p);
        background-color: var(--c-boundary);
        font-weight: 700;
      }

      .option {
        padding: 10px;

        &:hover {
          background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
          cursor: pointer;
        }

        &.disabled {
          color: var(--c-muted);
          cursor: default;

          &:hover {
            // background-color: transparent;
          }
        }
      }
    }

  }

}

.select.multi {

  .input {

    font-size: 1rem;

    .search {
      display: none;
      
      float: left;
      margin: 0 10px 10px 0;

      input[type=text] {
        display: block;
        outline: none;
        height: auto;
        box-sizing: border-box;
        font-family: var(--monospace-stack), monospace;
        font-size: 1rem;
        padding: 5px 10px;
      }
    }

    .multiselected {
      cursor: default;

      .value {
        float: left;
        position: relative;
        margin: 0 10px 10px 0;
        padding: 5px 10px;
        max-width: 100%;
        background-color: var(--colour-cp-highlight, var(--colour-main, #efefef));
        color: #000;
        line-height: 1rem;
        cursor: pointer;
        font-family: var(--monospace-stack), monospace;
        
        .label {
          font-size: 1rem;
          cursor: pointer;
        }
        .unselect {
          display: none;
        }
      }

    }


    .multiselected {
    }

  }

  &.empty {
    .input {
      .search {
        display: block;
        margin: 0;
      }
    }
  }

  &.open {

    .input {
      box-shadow: 0 4px 5px rgb(0 0 0 / 15%);

      .search {
        display: block;
      }
    }

  }

  .dropdown {
    border: none;
    border-top: 1px solid #000;
    box-shadow: 0 4px 5px rgb(0 0 0 / 15%);
    
    .options {
      padding: 10px 10px 0 10px;
      background-color: #f6f6f6;

      .option {
        display: inline-block;
        padding: 5px 10px;
        background-color: #fff;
        font-size: 1rem;
        cursor: pointer;
        margin: 0 10px 10px 0;
        font-family: var(--monospace-stack), monospace;

        &:hover {
          // background-color: var(--colour-main);
        }
      }
    }

  }

}

.field-box > .select .input {
  background-color: var(--c-boundary);
}

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////


// button#generate-pdf, button#retrieve-data {
//   display: block;
//   width: calc(100% - 2px);
//   padding: 10px;
//   background-color: $colour-main;
//   margin: 20px 0 0 0;
//   font-family: $monospace-stack;
//   font-size: 16px;
//   font-weight: 700;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   border: 1px dashed #000;
//   cursor: pointer;
//   -webkit-transition: border .05s ease;
//   transition: border .05s ease;
// }

// button#retrieve-data {
//   margin-bottom: 10px;
// }

// button#generate-pdf.disabled, button#retrieve-data.disabled {
//   cursor: default;
//   border: 1px dashed #999;
//   color: #999;
//   background-color: transparent;
// }

// button#generate-pdf.loading {
//   background-size: 30px 30px;
//   background-image: linear-gradient(45deg, rgba(255, 228, 122, 1) 25%, transparent 25%, transparent 50%, rgba(255, 228, 122, 1) 50%, rgba(255, 228, 122, 1) 75%, transparent 75%, transparent);
//   animation: barberpole 2s linear infinite;
//   cursor: default;
//   border: 1px dashed $colour-main;
//   color: transparent;
// }

// button#generate-pdf.loading:hover {
//   border: 1px solid $colour-main;
// }

// @keyframes barberpole {
//   from {
//     background-position: 0 0;
//   }
//   to {
//     background-position: 60px 30px;
//   }
// }

// button#generate-pdf:hover {
//   border: 1px solid #000;
// }

// button#generate-pdf:active {
//   width: calc(100% - 4px);
//   border: 2px solid #000;
//   outline: none !important;
//   margin: 19px 0 -1px 0;
// }

// button#generate-pdf.disabled:hover, button#retrieve-data.disabled:hover {
//   border: 1px dashed #999;
// }

span#download-pdf {
  cursor: pointer;
  text-decoration: underline;

  &.disabled {
    cursor: not-allowed;
  }
}

// input.commentsearch {
//   width: calc(100% - 20px);
//   margin: 20px 0 0 0;
//   border: none;
//   padding: 10px;
//   outline: none;
//   font-family: $monospace-stack;
// }

// span.comments-count {
//   font-family: $sans-serif-stack;
//   font-size: 14px;
// }

// div.comments-download {
//   font-family: $sans-serif-stack;
//   font-size: 14px;
//   text-decoration: underline;
//   cursor: pointer;
//   float: right;
// }

// button:focus {
//   outline: none !important;
// }

// span.PDF-disclaimer {
//   display: block;
//   width: 100%;
//   font-family: $sans-serif-stack;
//   font-weight: 300;
//   font-size: 12px;
//   margin: 10px 0 0 0;
// }

// span.PDF-warning {
//   display: block;
//   width: 40%;
//   min-width: 300px;
//   text-align: center;
//   font-family: $sans-serif-stack;
//   font-weight: 300;
//   font-size: 12px;
//   margin: 10px auto -10px auto;
//   color: red;
// }

// span.PDF-warning.hidden {
//   display: none;
// }

// div#controlpanel {
//   display: block;
//   width: 100%;
//   margin: 20px -20px;
//   padding: 20px;
//   background-color: #efefef;
// }

// div#controlpanel.loading {
//   background-size: 30px 30px;
//   background-image: linear-gradient(45deg, rgba(255, 255, 255, .4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .4) 50%, rgba(255, 255, 255, .4) 75%, transparent 75%, transparent);
//   animation: barberpole 2s linear infinite;
//   cursor: default;
// }


// div#progress-text {
//   display: block;
//   width: 100%;
//   background-color: rgba(0,0,0,.2);
//   margin: -20px -20px 20px -20px;
//   padding: 5px 20px;
//   font-family: $monospace-stack;
//   font-size: 16px;
//   font-weight: 700;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   text-align: center;
// }

// fieldset {
//   display: block;
//   width: calc(50% - 20px);
//   margin: 0;
//   padding: 0 20px 5px 0;
//   border: 0;
//   float: left;
//   font-family: $monospace-stack;
// }


// .chosen-container-single {

//   .chosen-single {
//     position: relative;
//     display: flex;
//     align-items: center;
//     overflow: hidden;
//     padding: 5px 10px;
//     height: 100%;
//     border: 0;
//     background-color: #f6f6f6;
//     background-clip: padding-box;
//     color: #000;
//     text-decoration: none;
//     white-space: nowrap;
//     line-height: 24px;

//     span {
//       display: block;
//       overflow: hidden;
//       margin-right: 26px;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//     }

//     abbr {
//       position: absolute;
//       top: 6px;
//       right: 26px;
//       display: block;
//       width: 12px;
//       height: 12px;
//       background: url("chosen-sprite.png") -42px 1px no-repeat;
//       font-size: 1px;

//       &:hover {
//         background-position: -42px -10px;
//       }
//     }

//     div {
//       position: absolute;
//       top: 0;
//       right: 0;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 18px;
//       height: 100%;

//       b {
//         display: block;
//         width: 12px;
//         height: 12px;
//         background: url("chosen-sprite.png") no-repeat 0 -4px;
//         filter: brightness(0%);
//       }
//     }
//   }

//   .chosen-single-with-deselect {
//     span {
//       margin-right: 38px;
//     }
//   }

//   .chosen-default {
//     color: #999;
//   }

//   .chosen-search {
//     position: relative;
//     z-index: 1010;
//     margin: 0;
//     padding: 0;
//     white-space: nowrap;

//     input[type="text"] {
//       margin: 0;
//       width: 100%;
//       height: auto;
//       outline: 0;
//       border: 0;
//       background-color: #f6f6f6;
//       font-size: 1rem;
//       line-height: normal;
//     }
//   }

//   .chosen-drop {
//     margin-top: -1px;
//     background-clip: padding-box;
//     border-top: 0;
//   }

//   &.chosen-container-single-nosearch {
//     .chosen-search {
//       position: absolute;
//       clip: rect(0, 0, 0, 0);
//       -webkit-clip-path: inset(100% 100%);
//               clip-path: inset(100% 100%);
//     }
//   }

//   .chosen-results {
//     li.active-result {
//       display: block;
//       width: auto;
//       margin: 0 -10px;
//       padding: 10px 10px;
//       background-color: #fff;
//       font-size: 1rem;
//       cursor: pointer;

//       &:hover {
//         background-color: $colour-cp-highlight;
//       }
//     }
//   }
// }

// .chosen-container-multi {

//   .chosen-choices {
//     position: relative;
//     overflow: hidden;
//     margin: 0;
//     padding: 0;
//     width: 100%;
//     height: auto;
//     border: 0;
//     background-color: #f6f6f6;
//     cursor: text;

//     li {
//       float: left;
//       list-style: none;

//       &.search-field {
//         margin: 0;
//         padding: 0;
//         white-space: nowrap;

//         input[type="text"] {
//           margin: 1px 0;
//           padding: 0;
//           height: 25px;
//           outline: 0;
//           border: 0 !important;
//           background: transparent !important;
//           -webkit-box-shadow: none;
//                   box-shadow: none;
//           color: #757575;
//           font-size: 1rem;
//           line-height: normal;
//           width: 25px;
//         }
//       }

//       &.search-choice {
//         position: relative;
//         margin: 0 10px 10px 0;
//         padding: 5px 10px;
//         max-width: 100%;
//         background-color: $colour-cp-highlight;
//         color: #000;
//         line-height: 1rem;
//         cursor: default;

//         span {
//           word-wrap: break-word;
//         }

//         .search-choice-close {
//           position: absolute;
//           width: 100%;
//           height: 100%;
//           top: 0;
//           left: 0;
//           display: block;

//           &:hover {
//             background-position: -42px -10px;
//             filter: brightness(0%);
//           }

//         }
//       }

//       &.search-choice-disabled {
//         padding-right: 5px;
//         border: 1px solid #ccc;
//         background-color: #e4e4e4;
//         color: #666;
//       }

//       &.search-choice-focus {
//         background: #d4d4d4;
        
//         .search-choice-close {
//           background-position: -42px -10px;
//         }
//       }

//     }
//   }

//   .chosen-results {
//     margin: 0;
//     padding: 10px 10px 0 10px;
//   }

//   .chosen-drop .result-selected {
//     display: none;
//     color: #ccc;
//     cursor: default;
//   }
// }

// .chosen-container-active {

//   &.chosen-with-drop {
//     -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
//             box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);

//     .chosen-single {
//       border-bottom-right-radius: 0;
//       border-bottom-left-radius: 0;

//       div {
//         border-left: none;
//         background: transparent;

//         b {
//           background-position: -18px -4px;
//         }
//       }
//     }
//   }

//   .chosen-choices li.search-field input[type="text"] {
//     color: #222 !important;
//   }

// }

// .chosen-disabled {
//   opacity: 0.5 !important;
//   cursor: default;

//   .chosen-single {
//     cursor: default;
//   }

//   .chosen-choices .search-choice .search-choice-close {
//     cursor: default;
//   }

// }

// /* @group Retina compatibility */
// @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
//   .chosen-rtl .chosen-search input[type="text"],
//   .chosen-container-single .chosen-single abbr,
//   .chosen-container-single .chosen-single div b,
//   .chosen-container-single .chosen-search input[type="text"],
//   .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
//   .chosen-container .chosen-results-scroll-down span,
//   .chosen-container .chosen-results-scroll-up span {
//     background-image: url("chosen-sprite@2x.png") !important;
//     background-size: 52px 37px !important;
//     background-repeat: no-repeat !important;
//   }
// }

// -------------------------------------------------------------------------- //
// PAGE: SURVEY LANDING PAGE

.reports {

  display: grid;
  
  grid-template-columns: .5fr .5fr;
  grid-gap: 0px;
  padding-bottom: 20px;

  & > a {

    display: grid;
    grid-gap: 40px;
    padding: 20px;
    // border-radius: 10px;

    grid-template-columns: 250px 1fr;

    font-family: $sans-serif-stack;
    text-decoration: none;

    .img {
      width: 100%;
      height: 250px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: var(--shadow, 0 0 10px #00000022);
      background-size: contain;
      background-position: center;
    }

    .text {

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;
      }

      p {
        line-height: 1.5rem;
        max-width: 400px;
      }

      [role="button"] {
        background-color: #000;
        color: #fff;
        padding: 10px 20px;
        align-self: flex-start;
        border-radius: 5px;
        font-weight: 700;
      }


    }

    &.disabled {
      cursor: not-allowed;
    }

    &:hover {
      // background-color: #fbfbfb;

      .text {
        [role="button"] {
          color: #fedb4a;
        }
      }
      
      &.disabled {
        .text {
          [role="button"] {
            color: #fff;
          }
        }        
      }
    }

  }

}

@media (max-width: 1450px) {
  .reports {
    grid-template-columns: 1fr;
  }
}



// -------------------------------------------------------------------------- //
// SECTION: FREE RESOURCES

main.resources {
  padding: 40px;

  div.banner {
    background-color: $colour-main;
    padding: 40px;
    border-radius: 5px;
    margin: 0 0 40px 0;
    display: flex;
    align-items: space-between;

    h1 {
      font-family: $sans-serif-stack;
      font-size: 4rem;
      font-weight: 700;
      line-height: 3.6rem;
      letter-spacing: 0px;
    }
  }

  .search {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
    align-items: center;
    margin-bottom: 20px;

    span {
      font-family: $sans-serif-stack;
      font-weight: 700;
      font-size: 1.2rem;
    }

    input {
      display: block;
      border: 1px solid #cdcdcd;
      border-radius: 5px;
      padding: 20px;
      outline: none;
      font-family: $monospace-stack;
      font-size: 1rem;
    }
  }

  .resources-wrapper {
    display: grid;
    grid-template-columns: repeat(3,calc((100% - 40px)/3));
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    a.resource {
      display: block;
      border-radius: 5px;
      border: 1px solid #cdcdcd;
      padding: 20px;
      font-family: $sans-serif-stack;
      text-decoration: none;
      font-weight: 700;

      &:hover {
        border-color: #000;
      }
    }
  }

}


// -------------------------------------------------------------------------- //
// SECTION: DATA

// -------------------------------------------------------------------------- //
// MAIN MENU (on left of screen)

nav {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: block;
  background-color: $colour-main-translucent;
  width: 300px;
  margin: 0 20px 0 0;
  
  hr {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    opacity: .6;
    border: none;
    margin: 10px 0; 
  }
}


// SURVEY SELECTOR

.survey-offering-selector {
  position: relative;
  width: calc(100% - 10px);
  margin: 0 0 10px 10px;
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: .8px;
  color: $colour-main;
  background-color: transparent !important;

  &::selection {
    background-color: $colour-main !important;
    color: #000 !important;
  }

  .input {
    border-radius: var(--border-radius, 5px);

    .selected {
      padding: 6px 10px 8px 10px;
      cursor: pointer;
      background-color: var(--colour-nav-survey-background, #000000);
      border-radius: var(--border-radius, 5px);
      font-size: 0.95rem;
      font-family: $sans-serif-stack;

      &:before {
        background-color: $colour-main;
        -webkit-mask-image: url(../static/img/plus-solid.svg);
        mask-image: url(../static/img/plus-solid.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position: center !important;
        mask-position: center !important;
        
        position: absolute;
        content: "";
        top: 9px;
        right: 10px;
        background-position: 0 0;
        width: 12px;
        height: 12px;
      }

      &:after {
        content: "Survey";
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: block;
      }

      &.value-placeholder:after {
        content: "To select a survey";
      }
    }

    .icon {
      display: none;
    }
  }

  .select.open {
    .input {
      .selected {
        &:before {
          -webkit-mask-image: url(../static/img/minus-solid.svg);
          mask-image: url(../static/img/minus-solid.svg);
        }
      }
    }
  }

  .dropdown {
    border: 0;
    position: relative;
    background-color: transparent;
    box-shadow: none;

    .options {

      position: relative;
      background-color: transparent;
      z-index: 99;
      margin-top: 3px;
      border-radius: var(--border-radius, 5px);

      max-height: none;
      overflow-y: auto;

      .option {

        padding: 6px 10px 8px 10px;
        cursor: pointer;
        background-color: var(--colour-nav-survey-background, #000000);
        font-size: 0.95rem;
        font-family: $sans-serif-stack;

        &:hover {
          color: #fff;
          background-color: var(--colour-nav-survey-background, #000000);
        }

        &.disabled {
          cursor: default;
          background-color: var(--colour-nav-survey-background-translucent, #00000066);
        }

        &.disabled:hover {
          color: $colour-main;
        }

        &:after {
          content: "Survey";
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          display: block;
        }

        &.active,
        &.value-placeholder {
          display: none;
        }
      }
    }
  }

}

// PAGE TYPE SELECTOR

div.menu-type-box {
  display: block;
  margin: 0 0 0 20px;
}

div.menu-type {
  float: left;
  font-family: $sans-serif-stack;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: .7px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 0 0 0px 0;
  cursor: pointer;

  &.current-type {
    border-bottom: 4px solid var(--colour-nav-border, #fff);
    cursor: default;
  }
}

// ACCORDION MENU

ul.cd-accordion-menu {
  clear: both;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: -6px;
  font-family: $sans-serif-stack;
  font-weight: 400;
  font-size: .95rem;

  strong {
    font-weight: 700;
    strong {
      font-weight: 400;
    }
  }

  ul {
    /* by default hide all sub menus */
    display: none;
    padding: 0;
    margin-left: 20px;
    border-left: 4px solid var(--colour-nav-border, #fff);

    ul{
      /* by default hide all sub menus */
      padding: 0;
      margin-left: 28px;
      border-left: 1px solid var(--colour-nav-border, #fff);

      label {
        padding-left: 28px;

        &::before {
          left: 28px;
        }
      }

      a {
        padding-left: 12px;
      }

      ul {

        label {
          padding-left: 28px;

          &::before {
            left: 28px;
          }
        }

        a {
          padding-left: 12px;
        }
      }
    }

    label {
      padding-left: 28px;

      &::before {
        left: 28px;
        background: var(--colour-nav-border, #fff);
      }
    }

    a {
      padding-left: 12px;
    }
  }

  li {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input[type=checkbox] {
    /* hide native checkbox */
    position: absolute;
    opacity: 0;
  }

  label,
  a {
    position: relative;
    display: block;
    padding: 4px 6px 4px 20px;
    color: var(--colour-text, #000000);
    text-decoration: none;
  }

  a:hover {
    text-shadow: 2px 2px 0px var(--colour-nav-border, #fff);
  }

  label {
    font-weight: 700;
    cursor: pointer;
    
    strong {
      font-weight: 300;
    }
    
    &::before {
      /* icons */
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      margin-left: -22px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      /* arrow icon */
      left: 18px;
      background-position: 0 0;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);

      background-color: var(--colour-text, #000000);
      -webkit-mask-image: url(../static/img/caret.svg);
      mask-image: url(../static/img/caret.svg);
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-position: center !important;
      mask-position: center !important;
    }
  }

  &.animated label::before {
    /* this class is used if you're using jquery to animate the accordion */
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }

}

.cd-accordion-menu input[type=checkbox]:checked + label::before {
  /* rotate arrow */
  -webkit-transform: translateY(-50%) rotate(90deg);
  -moz-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}
.cd-accordion-menu input[type=checkbox]:checked + label + ul,
.cd-accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
  /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
  /* show children when item is checked */
  display: block;
}

.cd-accordion-menu > li:last-of-type > label,
.cd-accordion-menu > li:last-of-type > a,
.cd-accordion-menu > li > ul > li:last-of-type label,
.cd-accordion-menu > li > ul > li:last-of-type a {
  box-shadow: none;
}

li {

  &.menu-section-heading {
    display: block;
    font-weight: 300;
    margin: 20px 0 10px 0px;
    background-color: var(--colour-nav-border, #fff);
    color: var(--colour-text, #000000);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    overflow: hidden;
  }

  &.menu-section-divider {
    display: block;
    height: 0px;
    margin: 10px 0 10px 0px;
    overflow: hidden;
    border-bottom: 1px dashed var(--colour-nav-border, #fff);
  }
}

span.menu-section-heading {
  display: inline-block;
  background-color: var(--colour-nav-border, #fff);
  padding: 2px 4px;
}

a.currentPage {
  text-shadow: 2px 2px 0px var(--colour-nav-border, #fff);
}

div.menu-panel-placeholder {
  display: block;
  clear: both;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 0 0 10px;
  color: var(--colour-text, #fff);
  height: 290px;
  text-align: center;
  font-family: $sans-serif-stack;
  font-size: 14px;
}

// -------------------------------------------------------------------------- //
// LOGIN PAGES

div.centre-box {
  grid-column: 1 / 3;
  grid-row: 1 / 4;
  display: block;
  align-self: center;
  justify-self: center;
  padding-bottom: 100px;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;

  &.wide {
    width: 700px;
  }
}

.center {
  text-align: center;
}

div.centre-box a {
  color: #000 !important;
}

// span.thankyou-head {
//   display: block;
//   font-family: $sans-serif-stack;
//   font-weight: 700;
//   font-size: 3rem;
// }

// span.thankyou-message {
//   display: block;
//   font-family: $sans-serif-stack;
//   font-weight: 700;
//   font-size: 1.4rem;
//   margin: 0 0 40px 0;
// }

// a.telephone {
//   display: inline-block;
//   background-color: $colour-main-tint;
//   text-decoration: none;
//   color: #000 !important;
//   padding: 0 3px;
//   font-family: $monospace-stack;
//   font-weight: 700;
// }

// img.thankyou-logo {
//   display: block;
//   width: 30%;
//   margin: 40px auto 0 auto;
// }

img.squiggle {
  display: block;
  width: 50%;
  margin: 4em auto;
}

span.loginlabel {
  font-family: $sans-serif-stack;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.login input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin : 0 0 0 0;
  border: 0;
  padding: 10px;
}

.login button {
  display: block;
  width: auto;
  float: right;
  margin : 0 0 0 0;
  border: 0;
  padding: 10px 20px;

  font-family: $sans-serif-stack;
  font-size: 14px;
  font-weight: 700;

  background-color: var(--colour-login-button, #000);
  color: var(--colour-login-button-text, $colour-main);
  cursor: pointer;
}

.login button:hover {
  background-color: var(--colour-login-button-hover, #000);
  color: var(--colour-login-button-text-hover, #fff);
}

.login .error {
  display: block;
  width: 100%;
  padding-top: 20px;
  text-align: center;
  clear: both;
  color: red;
  font-family: $sans-serif-stack;

  strong {
    display: block;
    margin-bottom: 10px;
  }
  a {
    color: red !important;
  }
}

// -------------------------------------------------------------------------- //
// A MULTI-SELECT PLUGIN
// Don't think we're using it anymore.

.ms-container {
  width: 100%;
}

/*.ms-container:after{
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  font-size: 0;
  clear: both;
  min-height: 0;
  visibility: hidden;
}
*/

.ms-container .ms-selectable {
  margin-bottom: 20px;
}

div#cp.compact .ms-container .ms-selectable {
  margin-bottom: 10px;
}

.ms-container .ms-selectable:before, .ms-container .ms-selection:before {
  display: block;
  font-weight: 700;
  background-color: #efefef !important;
  color: #000;
  padding-bottom: 10px;
}

.ms-container .ms-list {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
  -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
  -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
  -o-transition: border linear 0.2s, box-shadow linear 0.2s;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  border: 1px solid #ccc;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  height: 100px;
  padding: 0;
  overflow-y: auto;
}

.ms-selection .ms-list {
  height: auto;
  min-height: 20px;
  max-height: 100px;
  margin-bottom: 20px;
}

.ms-container .ms-list.ms-focus{
  outline: 0;
}

.ms-container ul{
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.ms-container .ms-optgroup-container{
  width: 100%;
}

.ms-container .ms-optgroup-label{
  margin: 0;
  padding: 5px 0px 0px 5px;
  cursor: pointer;
  color: #999;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
  border-bottom: 1px #eee solid;
  padding: 2px 10px;
  color: #555;
  font-size: 14px;
}

.ms-container .ms-selectable li:hover,
.ms-container .ms-selection li:hover{
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  background-color: #08c;
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled{
  background-color: #efefef;
  color: #444;
  cursor: text;
}

// -------------------------------------------------------------------------- //
// PAGE DETAILS (accordion below interactive elements)

div.info {

  padding-bottom: 20px;
  
  div {
    width: 600px;
    max-width: 100%;
    
    ul {
      list-style-type: disc;
      
      &.questions {
        font-family: $sans-serif-stack;

        li {
          a {
            text-decoration: none;

            &:hover {
              background-color: #eee;
            }
          }

          ul {
            li {
              a {
                text-decoration: none;

                &:hover {
                  background-color: #eee;
                }
              }
            }
          }
        }
      }
    }
    
  }
}

span.MathJax_CHTML {
  font-size: 18px !important;
}

ol.literature {
  list-style-type: none;
  font-size: .8rem;
  padding-left: 20px;
  
  li {
    margin-bottom: 10px;

    a {
      text-decoration: none;

      &:hover {
        background-color: #eee;
      }
    }
  }
}

ol.resilience-score {
  list-style-type: decimal;
  font-size: .8rem;
  padding-left: 20px;
}

// -------------------------------------------------------------------------- //
// PAGE: COMMENTS

/*
F hsla(103, 71%, 41%, 1)
M hsla(267, 86%, 55%, 1)
D hsla(199, 100%, 50%, 1)
T hsla(14, 100%, 50%, 1)
P hsla(199, 18%, 33%, 1)
*/

div#comments_container {
  width: 100%;
  padding-bottom: 10px;
}

div.comment {
  display: block;
  overflow-wrap: break-word;
  width: calc(100% / 3 - 30px);
  padding: 10px;
  border-right: 10px solid #fff;
  border-bottom: 10px solid #fff;
  float: left;
  background-color: var(--comment-background, #fff4c8);
  
  .grade {
    display: inline-block;
    font-family: $monospace-stack;
    font-weight: 700;
    margin: 0 0 0 20px;
    font-size: 12px;
  }
  
  &.F {
    background-color: var(--colour-female-cellophane, hsla(103, 71%, 41%, .4));
    .grade {
      color: var(--colour-female, hsla(103, 71%, 41%, 1));
    }
  }

  &.M {
    background-color: var(--colour-male-cellophane, hsla(267, 86%, 55%, .4));
    .grade {
      color: var(--colour-male, hsla(267, 86%, 55%, 1));
    }
  }

  &.D {
    background-color: var(--colour-other-cellophane, hsla(199, 100%, 50%, .4));
    .grade {
      color: var(--colour-other, hsla(199, 100%, 50%, 1));
    }
  }

  &.O {
    background-color: var(--colour-other-cellophane, hsla(14, 100%, 50%, .4));
    .grade {
      color: var(--colour-other, hsla(14, 100%, 50%, 1));
    }
  }

  &.P {
    background-color: var(--colour-prefer-cellophane, hsla(199, 18%, 33%, .4));
    .grade {
      color: var(--colour-prefer, hsla(199, 18%, 33%, 1));
    }
  }
}

div.button.gender.active {

  &.F {
    background-color: hsla(103, 71%, 41%, .4);
  }

  &.M {
    background-color: hsla(267, 86%, 55%, .4);
  }

  &.D { 
    background-color: hsla(199, 100%, 50%, .4);
  }

  &.O {
    background-color: hsla(14, 100%, 50%, .4);
  }

  &.P {
    background-color: hsla(199, 18%, 33%, .4);
  }

}

// -------------------------------------------------------------------------- //
// PAGE: HISTOGRAM

rect {
  stroke-width: 1;
}

rect.male {
  fill: var(--colour-male-translucent, hsla(267, 86%, 55%, .7));
  stroke: var(--colour-male, hsla(267, 86%, 55%, 1));
}

rect.female {
  fill: var(--colour-female-translucent, hsla(103, 71%, 41%, .7));
  stroke: var(--colour-female, hsla(103, 71%, 41%, 1));
}

.avgsCore path {
  stroke-width: 6;
  fill: none;

  &.female {
    stroke: var(--colour-female, hsla(103, 71%, 41%, 1));
  }

  &.male {
    stroke: var(--colour-male, hsla(267, 86%, 55%, 1));
  }
}

.avgsComp path {
  stroke-width: 3;
  fill: none;

  &.female {
    stroke: hsla(0, 100%, 50%, 1);
  }

  &.male {
    stroke: hsla(0, 100%, 50%, 1);
  }
}

g.scaffold path {
  z-index: -2;
}

g.tick {
  z-index: 99;
}

text#temp {
  font-size: 16px;
  font-family: $monospace-stack;
  font-weight: bold;
  text-shadow: -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 1px 1px 0 #fff,
              0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

text#temp.female {
  fill: var(--colour-female, hsla(103, 71%, 41%, 1));
}

text#temp.male {
  fill: var(--colour-male, hsla(267, 86%, 55%, 1));
}

.complines path {
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 10,10;
  stroke: red;
}


// #gnLeft, #gnRight, #gnOff, #gnOne, #gnTwo {
//   width: calc(100% / 3);
//   min-height: 20px;
//   list-style-type: none;
//   margin: 0;
//   padding: 5px 0 0 0;
//   float: left;
// }

// .gender-option {
//   display: block;
//   width: calc(100% - 20px);
//   margin: 0 auto 5px 0;
//   padding: 5px 5px;
//   font-size: .8rem;
//   font-family: $monospace-stack;
//   cursor: grab;
// }

// .gender-option:hover {
//   box-shadow: 0 0 3px #000;
// }

// .gender-placeholder {
//   display: block;
//   width: calc(100% - 20px);
//   margin: 0 auto 5px 0;
//   padding: 0 5px;
//   font-size: .8rem;
//   height: 24px;
//   background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAH0lEQVQYV2NkQAX/GZH4/xkYGBhhAmAOSBJEwDkgAQCCrgQEjpMcPgAAAABJRU5ErkJggg==) repeat;
// }

// #gnRight li.gender-option, #gnTwo li.gender-option {
//   background-color: hsla(260, 71%, 80%, 1);
//   border: 1px solid hsla(267, 86%, 55%, .6);
// }

// #gnLeft li.gender-option, #gnOne li.gender-option {
//   background-color: hsla(120, 38%, 75%, 1);
//   border: 1px solid hsla(103, 71%, 41%, .6);
// }

// #gnOff li.gender-option {
//   background-color: hsla(0, 0%, 94%, 1);
//   border: 1px solid hsla(0, 0%, 55%, .6);
// }

// .gender-group {
//   display: block;
//   width: calc(100% - 10px);
//   margin: 0 auto 5px 0;
//   padding: 0;
//   border: 0;
//   background-color: transparent !important;
//   font-size: .8rem;
//   font-family: $monospace-stack;
//   cursor: default;
// }

// span.column {
//   display: block;
//   float: left;
//   margin: 0 0 -500em 0;
//   padding: 0 20px 500em 25px;
//   border: 0;
//   border-left: 4px solid $colour-main;
// }

// span.column.half {
//   width: calc((100% / 2) - 49px);
// }
// span.column.quarter {
//   width: calc((100% / 4) - 49px);
// }

// span.column .icon {
//   display: block;
//   position: absolute;
//   width: 40px;
//   margin: -8px 0 0 -47px;
//   padding: 0;
//   border: 0;
//   font-size: 2rem;
//   text-align: center;
//   background-color: #fff;
// }

// span.simplecolumn {
//   display: block;
//   float: left;
//   margin: 0;
//   padding: 0 20px 0 0;
//   border: 0;
//   width: calc((100% / 2) - 20px);
// }

// span.category {
//   display: block;
//   font-family: $sans-serif-stack;
//   font-weight: 400;
//   font-size: 1.2rem;
//   margin: 0 0 4px 0;
// }

// -------------------------------------------------------------------------- //
// PAGE: SNAPSHOT INTERACTIVE

// New snapshot below.

.snapshot-table {

  margin: 0 0 20px 0;
  // border-bottom: 1px solid #000;

  .row {
    display: grid;
    grid-template-columns: 1fr repeat(2, 80px);
    border-bottom: 1px solid #ccc;

    .item {
      padding: 5px 10px;
      font-size: .9rem;
    }

    .data {
      font-family: $monospace-stack;
      text-align: center;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.header {
      // border-top: 3px solid #000;
      // font-weight: 700;
      // background-color: #f6f6f6;
      font-family: $sans-serif-stack;
      text-transform: uppercase;
      font-size: .8rem;
      letter-spacing: 1px;

      & > div {
        padding: 5px 10px;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .data {
        font-family: unset;
        font-weight: 300;
      }
    }
  }

}

.data {
  &.diff-15 {
    background-color: var(--colour-diff-15);
    border-top: 1px solid var(--colour-diff-15);
  }
  &.diff-10 {
    background-color: var(--colour-diff-10);
    border-top: 1px solid var(--colour-diff-10);
  }
  &.diff-5 {
    background-color: var(--colour-diff-5);
    border-top: 1px solid var(--colour-diff-5);
  }
  &.diff-0 {
    background-color: var(--colour-diff-0);
    border-top: 1px solid var(--colour-diff-0);
  }
  &.diff0 {
    background-color: var(--colour-diff0);
    border-top: 1px solid var(--colour-diff0);
  }
  &.diff5 {
    background-color: var(--colour-diff5);
    border-top: 1px solid var(--colour-diff5);
  }
  &.diff10 {
    background-color: var(--colour-diff10);
    border-top: 1px solid var(--colour-diff10);
  }
  &.diff15 {
    background-color: var(--colour-diff15);
    border-top: 1px solid var(--colour-diff15);
  }
}

.snapshot-text {
  width: auto;
  margin: 10px 50px 20px 50px;
  text-align: center;
  font-style: italic;

  p {
    line-height: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }
}

// Old snapshot below.

table.snapshot {
  width: 100%;
  text-align: left;
  border: 0px solid #efefef;
  // border-collapse: collapse;
  margin: 0 0 24px 0;
  
  th {
    font-weight: 700;
    text-transform: none;
    letter-spacing: 0;
    font-size: 15px;
    border: none;
    padding: 2px 0 4px 0;
    font-family: Arial, Helvetica, sans-serif;

    &.reporting-area {
      width: 150px;
      padding-left: 0;
    }

    &.data {
      text-align: center;
      width: 72px;
    }
  }

  td {
    font-family: $serif-stack;
    font-size: 15px;
    padding: 4px 6px;
    
    &.domain {
      background-color: #efefef;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
    }

    &.data {
      text-align: center;
      font-family: $monospace-stack;
      font-size: 16px;
      width: 60px;
    }

    &.first-col {
      width: 150px;
      padding-left: 0;
      text-align: center;
    }

    &.reporting-area {
      vertical-align: text-top;
      width: 150px;
      padding-left: 0;
    }

    &.factor-score {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
    }

    &.title{
      background-color: #f6f6f6;
    }
  }


  tr {
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;

    &:nth-child(even) {
      background-color: transparent;
    }

    &:hover {
      background-color: #efefef;

      td.reporting-area{
        background-color: #fff;
      }
    }

  }

  a.snapshot-link {
    display: block;
    position: relative;
    width: calc(100% + 12px);
    text-decoration: none;
    color: #000;
    height: 24px;
    line-height: 24px;
    margin: -4px -6px -4px -6px;
    z-index: 0;
    
    &:hover::after {
      content: 'go to detailed view';
      position: absolute;
      top: 2;
      right: 0;
      font-family: Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      font-size: .8rem;
      letter-spacing: 1px;
      margin: 0 10px 0 0;
      padding-left: 20px;
      line-height: 24px;
      color: #444;
      text-decoration: underline;
      text-decoration-color: $colour-main;
      background-image: linear-gradient(
                          to right,
                          rgba(239,239,239,0),
                          rgba(239,239,239,1) 20px
                        );
      z-index: 99;
    }
  }


}

div.snapshot-header {
  display: block;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;
  
  table {
    margin-bottom: 10px;
    
    tr {
      border: none;
      border-bottom: 1px solid #000;
      
      &:hover {
        background-color: transparent;
      }
    }
    
    th {
      padding-top: 20px;
    }
  }
}

span.snapshot-key {
  display: block;
  float: left;
  font-family: $monospace-stack;
  font-size: 15px;
  font-weight: 700;
  margin: 0 20px 0 0;
  width: 60px;
  text-decoration: none;
  color: #000;
}

table.snapshot-legend {
  width: auto;
  margin: 0 auto 20px auto;
  text-align: center;
  border: 0;
  border-collapse: collapse;

  tr {
    background-color: transparent !important;
  }

  td {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    padding: 4px 6px;

    &.key {
      border: 0;
      width: 80px;
      font-family: Arial, Helvetica, sans-serif;
    }

    &.scale-left {
      text-align: left;
      border-right: 2px solid #000;
      padding-left: 0;
    }

    &.scale-right {
      text-align: right;
      border-left: 2px solid #000;
      padding-right: 0;
    }

    &.key {
      border-top: none !important;
      width: 80px;
    }

    &.key-left {
      border-right: 2px solid #000;
    }

    &.key-right {
      border-left: 2px solid #000;
    }

    &.leftcol {
      width: 80px;
    }

  }
}

td {

  &.diff-15 {
    background-color: var(--colour-diff-15);
    border-top: 1px solid var(--colour-diff-15);
  }
  &.diff-10 {
    background-color: var(--colour-diff-10);
    border-top: 1px solid var(--colour-diff-10);
  }
  &.diff-5 {
    background-color: var(--colour-diff-5);
    border-top: 1px solid var(--colour-diff-5);
  }
  &.diff-0 {
    background-color: var(--colour-diff-0);
    border-top: 1px solid var(--colour-diff-0);
  }
  &.diff0 {
    background-color: var(--colour-diff0);
    border-top: 1px solid var(--colour-diff0);
  }
  &.diff5 {
    background-color: var(--colour-diff5);
    border-top: 1px solid var(--colour-diff5);
  }
  &.diff10 {
    background-color: var(--colour-diff10);
    border-top: 1px solid var(--colour-diff10);
  }
  &.diff15 {
    background-color: var(--colour-diff15);
    border-top: 1px solid var(--colour-diff15);
  }
}

// -------------------------------------------------------------------------- //
// PAGE: GEMFINDER

.explore {
  display: block;
  padding: 20px;
  border: 1px solid #000;
  margin-bottom: 20px;
  text-decoration: none;
}

.blurb {
  margin: 0 0 20px 0;
  font-family: $sans-serif-stack;
  font-size: 1rem;
  width: 800px;
  max-width: 100%;
  line-height: 1.6rem;

  em {
    font-style: normal;
    
    background-color: #fedb4a22;

    text-decoration: underline;
    text-decoration-color: #fedb4a;
    text-decoration-thickness: 3px;
    text-decoration-skip-ink: none;
  }
}

div.example-box {
  // display: grid;
  // grid-template-columns: auto 1fr;
  // grid-column-gap: var(--p);
  // align-items: center;
  margin: 20px 0 40px 0;

  font-family: $sans-serif-stack;

  h2 {
    display: block;
    margin: 0;
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: var(--p);
  }

  .examples {
    display: flex;
    flex-wrap: wrap;

    & > div {
      // border: 1px solid $colour-main;
      background-color: #f6f6f6;
      font-size: 0.9rem;
      border-radius: 5px;
      padding: 5px 10px;
      margin: 0 var(--p) var(--p) 0;
      cursor: pointer;

      &:hover {
        background-color: $colour-main;
      }
    }
  }
}


// Gemfinder factors table.
.factors-table {

  padding: 0 0 20px 0;
  font-family: $sans-serif-stack;

  .row {
    display: grid;
    grid-template-columns:  .4fr .4fr .2fr;
    border-radius: 10px;

    padding: 5px 0;
    margin-bottom: 10px;

    &.header {
      font-family: $sans-serif-stack;
      text-transform: uppercase;
      font-size: .8rem;
      letter-spacing: 1px;
      margin-bottom: 5px;

      & > div {
        padding-left: 15px;
      }

      // div:nth-child(1) {
      //   padding-left: 15px;
      // }
    }

    .targets {
      padding-left: 40px;
      opacity: .6;
    }
    .factor {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      font-weight: 600;
      padding: 5px 15px;

    }
    .item {
      grid-column: 1 / 2;
      padding: 5px 20px 5px 15px;
      border-top: 1px solid #fff;

      &:nth-child(1)
      // &:nth-child(2)
      {
        border-top: none;
      }
    }
    .strength-challenge {
      grid-column: 2 / 3;
      padding: 5px 0 5px 15px;
      border-top: 1px solid #fff;

      &:nth-child(1), &:nth-child(2) {
        border-top: none;
      }
    }
  }

}

div.gemfinder {

  padding-bottom: 20px;

  header {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: end;
    grid-row-gap: 5px;

    padding: 0;
    width: 100%;
    margin: 20px 0 10px 0;
    
    h2 {
      margin: 0;
    }

    span.better {
      font-weight: 400;
      font-size: .8rem;
      text-transform: uppercase;
      align-self: center;
      text-align: right;
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      font-family: $sans-serif-stack;
    }

    span.subtitle {
      grid-column: 1 / 2;
      font-family: $sans-serif-stack;
      font-size: .9rem;
      // color: #666;
    }


    button {
      display: flex;
      align-items: center;
      border: 0;
      background-color: transparent;
      border-radius: 0;
      font-size: 20px;
      width: auto;
      // padding: 20px 15px;
      // background-color: #fedb4a22;
      color: #000;
      cursor: pointer;
      position: relative;

      .tooltip {
        visibility: hidden;
        content: attr(data-label);
        position: absolute;
        top: calc(100% - 25px);
        right: calc(100% + 80px);
        transform: translateX(50%);
        white-space: nowrap;
        // min-width: 100px;
        box-sizing: border-box;
        
        background-color: #000;
        color: #fff;  
        padding: 5px 10px;

        font-size: .8rem;
        // font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 5px;
        z-index: 99;
      }

      &:hover {
        .tooltip {
          visibility: visible;
        }
      }
    }
  }

  .not-found {
    width: 100%;
    display: grid;
    height: 100px;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    border: 1px solid #efefef;
    font-family: $monospace-stack;
    border-radius: 5px;
    margin: 20px 0 0 0;

    &.clickable {
      cursor: pointer;
      &:hover {
        border-color: #aaa;
      }
    }

  }

  .item {
    border-top: 1px solid #f6f6f6;
    font-family: $sans-serif-stack;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-column-gap: 20px;

    &.expanded {
      background-color: #fcfcfc;
      margin: 0 -20px;
      padding: 0 20px;
      border-top: 1px solid #efefef;
      // border: 1px solid #cdcdcd;
      // margin-top: -1px;
      // background-color: #f6f6f6;
      // box-shadow: 0 0 2px #cdcdcd;
      // border-radius: 5px;
      // margin: -1px -10px 0 -10px;
      // padding: 0 9px;
    }

    .row {
      grid-column: 1 / 3;
      display: grid;
      grid-template-columns: 20px 1fr auto;
      grid-column-gap: 20px;
      cursor: pointer;
      padding: 10px 0;

      .caret, .significance {
        justify-self: center;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      &.sig-high {
        .caret, .significance {
          color: #0d47a1;
        }
      }
      &.sig-med {
        .caret, .significance {
          color: #2196f3;
        }
      }
      &.sig-low {
        .caret, .significance {
          color: #bbdefb;
        }
      }
      &.sig-none {
        .significance {
          color: #f6f6f6;
        }
      }

      .caret {
        display: none;
      }

      &:hover {
        .caret {
          display: block;
        }
        .significance {
          display: none;
        }
      }

      .direction {

        margin: -10px 0;
        align-items: start;
        padding-top: 8px;
        display: flex;

        span.A, span.B {
          font-weight: 700;
          width: 25px;
          border-radius: 5px;
          display: inline-block;
          text-align: center;
          margin: 0 5px;
          padding: 2px 0;

          &:last-child {
            margin-right: 0;
          }
        }

        span.A {
          background-color: #fedb4a;
        }
        span.B {
          background-color: #c0ca33;
        }
      }

    }



    .details {
      grid-column: 2 / 3;
      padding: 0 20px 10px 0;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      align-items: start;

      dl {
        grid-column: 1 / 3;
        display: flex;
        align-items: center;
        margin: 0 0 var(--p) 0;
        color: #444;
        font-size: .8rem;

        dt, dd {
          display: block;
          margin: 0;
          padding: 0;

          a, &.button {
            text-decoration: none;
            color: #000;
            cursor: pointer;
            &:hover {
              span {
                text-decoration: underline;
              }
            }
          }

          &::after {
            content: '·';
            margin: 0 0 0 var(--p);
          }
          &:last-of-type::after {
            display: none;
          }
        }

        dt {
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-right: 10px;

          .katex {
            text-transform: lowercase;
          }
        }

        dd {
          font-family: $monospace-stack;

          &.text {
            font-size: .9rem;
            font-weight: 700;
          }

          &::after {
            content: '·';
            margin: 0 var(--p);
          }
          &:last-of-type::after {
            display: none;
          }
        }


      }

      .plain-language {
        font-size: 1.2rem;

        max-width: 600px;
        margin-bottom: 20px;

        &::before {
          content: 'For example, ';
          // display: block;
          // font-size: .9rem;
        }

        span.A, span.B {
          font-weight: 700;
          width: 25px;
          border-radius: 5px;
          display: inline-block;
          text-align: center;
          // padding: 2px 0;
          margin-top: 2px;
        }

        span.A {
          background-color: #fedb4a;
        }
        span.B {
          background-color: #c0ca33;
        }
      }

      .table {
        display: grid;
        grid-template-columns: auto 1fr;

        span:nth-of-type(2n+1) {
          padding-right: 20px;
        }
        span:nth-of-type(2n) {
          font-family: $monospace-stack;
        }
      }

      svg {
        grid-column: 2 / 3;
      }
    }
  }

  h2 {
    margin: 20px 0 10px 0;
  }
}

.slide {

  h2 {
    margin-top: 20px;
  }

  .blurb {
    margin-bottom: 10px;
  }
}

nav.slides {
  display: grid;
  grid-template-columns: .5fr .5fr;
  grid-column-gap: 20px;
  width: 100%;
  padding: 20px 0 20px 0;
  background-color: transparent;

  button {
    border: none;
    display: block;
    border-radius: var(--border-radius);
    padding: 20px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: $sans-serif-stack;
    background-color: $colour-main;
    border: 1px solid $colour-main;
    opacity: .7;
    
    &:hover {
      opacity: 1;
    }

    &.inert {
      cursor: default;
      opacity: .1;
    }
  }
}

// -------------------------------------------------------------------------- //
// PAGE: HELPER PAGES  HELP ME CHOOSE
div.helper-pages-option {
  float: left;
  border: 4px solid $colour-cp-highlight;
  margin: 10px 20px 20px 0;
  padding: 20px;
  text-align: center;
  
  &.third-width {
    width: calc((100% - 160px - 24px) / 3);
  }

  &.half-width {
    width: calc((100% - 100px - 16px) / 2);
  }

  &.last-of-type {
    margin: 10px 0 0 0;
  }
}

span.helper-pages-option-icon {
  display: block;
  font-size: 2rem;
  margin: 0 0 10px 0;
}

span.helper-pages-option-name {
  display: inline-block;
  font-size: 1.1rem;
  font-family: $sans-serif-stack;
  text-decoration: none;
  /*background-color: $colour-main;*/
  margin: 0 0 20px 0;
}

span.helper-pages-option-description {
  display: block;
  text-decoration: none;
  margin: 0px 0 20px 0;
}

span.helper-pages-option-description.last-of-type {
  margin: 0;
}

span.helper-pages-option-description strong {
  font-weight: 700;
  font-family: inherit;
}

span.helper-pages-option-description em {
  font-style: italic;
}

a:hover div.helper-pages-option {
  background-color: $colour-cp-highlight;
}

// -------------------------------------------------------------------------- //
// PAGE: EXECUTIVE SUMMARY HELP ME CHOOSE

div.executive-summary-option {
  float: left;
  border: 4px solid $colour-cp-highlight;
  margin: 10px 20px 20px 0;
  padding: 20px;
  text-align: center;
  
  &.third-width {
    width: calc((100% - 160px - 24px) / 3);
  }

  &.half-width {
    width: calc((100% - 100px - 16px) / 2);
  }

  &.last-of-type {
    margin: 10px 0 0 0;
  }
}

span.executive-summary-option-icon {
  display: block;
  font-size: 2rem;
  margin: 0 0 10px 0;
}

span.executive-summary-option-name {
  display: inline-block;
  font-size: 1.1rem;
  font-family: $sans-serif-stack;
  text-decoration: none;
  /*background-color: $colour-main;*/
  margin: 0 0 20px 0;
}

div.executive-summary-option hr {
  display: block;
  width: 100px;
  max-width: 100%;
  height: 1px;
  background-color: #000;
  border: none;
  margin: 20px auto;
}

span.executive-summary-option-description {
  display: block;
  text-decoration: none;
  margin: 0px 0 20px 0;
}

span.executive-summary-option-description.last-of-type {
  margin: 0;
}

span.executive-summary-option-description strong {
  font-weight: 700;
  font-family: inherit;
}

span.executive-summary-option-description em {
  font-style: italic;
}

span.executive-summary-option-table {
  font-family: $sans-serif-stack;
}

span.executive-summary-option-smallcaps {
  font-weight: 300;
  font-family: $sans-serif-stack;
  font-variant: small-caps;
  letter-spacing: 1px;
}

span.executive-summary-option-monospace {
  display: block;
  font-family: $monospace-stack;
  margin: 6px 0 0 0;
}

span.exsum-1 {
  background-color: rgba(254,219,74,.2);
}

span.exsum-2 {
  background-color: rgba(254,219,74,.55);
}

span.exsum-3 {
  background-color: rgba(254,219,74,.9);
}

span.exsum-4 {
  background-color: rgba(254,219,74,0);
}

a:hover div.executive-summary-option {
  background-color: $colour-cp-highlight;

  .executive-summary-option-description .executive-summary-option-monospace .exsum-1 {
    background-color: rgba(255,255,255,.2);
  }

  .executive-summary-option-description .executive-summary-option-monospace .exsum-2 {
    background-color: rgba(255,255,255,.55);
  }

  .executive-summary-option-description .executive-summary-option-monospace .exsum-3 {
    background-color: rgba(255,255,255,.9);
  }
}

// -------------------------------------------------------------------------- //
// PAGE: SLOPEGRAPH

.slopegraphLines path {
  fill: none;
  z-index: 10;
}

.slopegraph.xAxis path.domain {
  display: none;
}

text.annotation {
  font-family: $monospace-stack;
  font-weight: 300;
  font-size: 10pt;
}

text.sglab {
  font-family: $sans-serif-stack;
  font-weight: 300;
  font-size: 10pt;
}

text.sglabhighlight {
  font-family: $sans-serif-stack;
  font-weight: 300;
  font-size: 10pt;
}

text.sgpc {
  font-family: $monospace-stack;
  font-weight: 300;
  font-size: 10pt;

  paint-order: stroke;
  stroke: #fff;
  stroke-width: 3px;
  stroke-linecap: butt;
  stroke-linejoin: miter;

  z-index: 11;
}


// -------------------------------------------------------------------------- //
// PAGE: STACKED BAR

.sb-legend {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 20px 160px;
  text-align: left;
  font-family: $monospace-stack;
  font-weight: 700;

  .sb-legend-item {
    display: inline-block;
    margin-right: 20px;
  }
}

.sb-table {
  width: 100%;
  margin: 0 0 20px 0;
  font-family: $monospace-stack;

  .sb-row {
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: 70px auto auto auto auto auto auto auto auto;

    .sb-cell {
      text-align: center;
      padding: 5px;
    }

    .sb-cell-comp {
      // text-align: right;
      font-size: .8rem;
      // padding: 5px 10%;
    }

    .sb-cell:first-child {
      font-weight: 700;
      text-align: right;

      border-right: 1px solid #000;
    }

    .sb-cell.sb-cell-comp:first-child {
      font-weight: 300;
    }
  }

  .sb-row:last-child {
    border-top: 1px solid #000;
    font-weight: 700;
  }

  .sb-row-border-top {
    border-top: 2px solid #000;
  }
}

// -------------------------------------------------------------------------- //
// PAGE: MINDSET

rect.mindset-shading {
  fill: $colour-main;
  opacity: .6;
  stroke-width: 0;
}

text.mindset-percentage {
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 64pt;
}

text.mindset-percentage-comp {
  font-size: 54pt !important;
}

text.mindset-percentage-res {
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 64pt;
  fill: #65dc18;
}

text.mindset-descript {
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 14pt;
}

text.mindset-subtext {
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 14pt;
  font-style: italic;
}

text.mindset-class {
  font-family: $sans-serif-stack;
  font-weight: 700;
  font-size: 22pt;
}

// -------------------------------------------------------------------------- //
// PAGE: ACTION PLANS

div.action-plan {
  & > div.field {
    display: grid;
    grid-template-columns: 200px auto;
    grid-column-gap: 20px;

    margin-bottom: 20px;

    & > div.input {
      display: grid;
    }
  }
  & > div#ap-buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    margin-bottom: 20px;

    & > div {
      margin-bottom: 0;
    }
  }

  div {
    &.meta {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &.step {
      p {
        background-color: #00a34a14;
        border-radius: 15px;
        padding: 20px;
        font-size: .85rem;
      }
    }
    &.default {
      p {
        background-color: #2a83a514;
        border-radius: 15px;
        padding: 20px;
        font-size: .85rem;
      }
    }
  }

  label {
    display: block;
    font-family: $sans-serif-stack;
    text-transform: uppercase;
    
    &.default,
    &.step {
      font-size: .85rem;
      font-weight: 600;
      border-radius: 20px;
      text-align: center;
      color: #fff;
      padding: 5px;
      margin: 0 0 10px 0;

    }
    
    &.meta {
      text-align: right;
      &::after {
        content: ':';
      }
    }
    &.default {
      background-color: #2a83a5;
    }
    &.step {
      background-color: #00a34a;
    }

  }

  p {
    font-family: $sans-serif-stack;
    font-size: .9rem;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
    border-radius: 0;
    border: none;
    background-color: #f6f6f6;
    padding: 20px;
    font-family: $sans-serif-stack;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  input[type="date"],
  input[type="number"],
  input[type="text"] {
    background-color: #f6f6f6;
    border: none;
    border-radius: 0;
    padding: 20px;
    font-family: $monospace-stack;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  input[type="text"] {
    font-family: $sans-serif-stack;
  }

  input[type="date"],
  input[type="number"],
  input[type="text"],
  textarea {
    &:focus {
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    }
  }

  div#ap-save,
  div#ap-update,
  div#ap-delete {
    grid-row: 1 / 2;
    margin-bottom: 0;

  }
  
}